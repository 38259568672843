import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  body {
    background-color: ${props =>
      props.darkBackground
        ? props.theme.colors.primary1
        : props.theme.colors[props.backgroundColor]};
  }

  html, body {
    height: 100%;
    padding: 0;
    margin: 0;
    font-size: 62.5%; /* base 10 rems */
    color: ${props => props.theme.colors.primary1};
    ${props =>
      props.bubbles
        ? `
      background-image: url(/images/background-bubbles.svg)
    `
        : ''};
  }

  * {
    font-size: 2rem;
    box-sizing: border-box;
  }

  a:focus,
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  h1 {
    ${props => props.theme.fonts.DMSerifDisplay}
    color: ${props => props.theme.colors.primary1};
    font-size: 4rem;
    margin: 0;
    padding: 0;
  }
  h2 {
    ${props => props.theme.fonts.DMSerifDisplay}
    color: ${props => props.theme.colors.primary1};
    font-size: 3.5rem;
    line-height: 2.8rem;
    margin: 0;
    padding: 0;
  }
  h3 {
    ${props => props.theme.fonts.DMSerifDisplay}
    color: ${props => props.theme.colors.primary2};
    font-size: 2.3rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 2.8rem;
    margin: 0;
    padding: 0;
  }
  h4 {
    ${props => props.theme.fonts.DMSerifDisplay}
    color: ${props => props.theme.colors.primary1};
    font-weight: 400;
    font-size: 2.3rem;
    letter-spacing: 0;
    line-height: 2.5rem;
    margin: 0;
    padding: 0;
  }
  h5 {
    ${props => props.theme.fonts.NunitoSans}
    color: ${props => props.theme.colors.primary2};
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 2.1rem;
    margin: 0;
    padding: 0;
  }

  p,
  li,
  address > span
  {
    ${props => props.theme.fonts.NunitoSans}
    font-weight: 600;
    color: ${props => props.theme.colors.primary1};
    font-size: 1.5rem;
    line-height: 2rem;
  }

  p {
    margin: 0;
  }

  address {
    font-style: normal;

    > span {
      + span::before {
        content: ' ';
      }

      :nth-child(-n+2) {
        :after {
          content: ',';
        }
      }
    }
  }

  a {
    font-size: 1.5rem;
  }
`

export default GlobalStyle
