const theme = {
  /* Breakpoints */
  bp: {
    sm: style => `@media only screen and (max-width: 400px) { ${style} }`,
    md: style =>
      `@media only screen and (min-width: 401px) and (max-width: 900px) { ${style} }`,
    lg: style =>
      `@media only screen and (min-width: 901px) and (max-width: 1440px) { ${style} }`,
    xl: style => `@media only screen and (min-width: 1441px) { ${style} }`,
  },
  resets: {
    button: `
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    `,
  },
  fonts: {
    DMSerifDisplay: `
      font-family: 'DM Serif Display', serif;
    `,
    NunitoSans: `
      font-family: 'Nunito Sans', sans-serif;
    `,
  },
  heights: {
    topBar: `80px`,
  },
  shadows: {
    topBar: `0px 2px 4px 0px #000;`,
    logo: `0px 1px 5px rgba(0, 0, 0, 0.131447)`,
  },
  colors: {
    primary1: '#49536D',
    primary2: '#5D667B',
    primary3: '#9FA8BD',
    primary4: '#BBBBBB',
    primary5: '#6B748A',
    primary6: '#ECF0F9',
    primary7: '#3D4556',
    primary8: '#3F5388',
    secondary1: '#C7970D',
    secondary2: '#F5D16B',
    secondary3: '#F5D16B',
    secondary4: '#FAE994',
    secondary5: '#FADA7F',
    secondary6: '#E1BC53',
    secondary7: '#977601',
    destructive1: '#FFD6CE',
    destructive2: '#F1AA9C',
    destructive3: '#FFECE8',
    destructive4: '#C74B34',
    destructive5: '#F8B9AD',
    neutral1: '#A39A7E',
    neutral2: '#F4F6F8',
    neutral3: '#F1EBDB',
    neutral4: '#FFFCEF',
    neutral5: '#F3EFE6',
    neutral6: '#DBD2BB',
    neutral7: '#FFFAEF',
    neutral8: '#F9F4E7',
    mutedTitle: '#D8D8D8',
    mutedTitleDarker: '#979797',
    background: '#49536D',
    lightBackground: '#5d667b',
    error: '#DE614A',
    warning: '#AC8105;',
    success: '#76C666',
    info: '#75B4DF',
    textCaption: '#5D667B',
  },
}

export default theme
